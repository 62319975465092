.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.GraphContainer {
    width: 90vw;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5vh;
}

.SpinnerWrapper {
    padding-top: 50vh;
}

@media (prefers-reduced-motion: no-preference) {
  .animatespin {
    animation: App-logo-spin infinite 1s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
